import React, { useState } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { PolicyContainer } from '../../../../../utils/style';
import Input from '../../../../../../../../../common/ui/Input/input';
import Keyboard from '../../../../common/keyboard';
import PolicyBB from './policyBB';
import { updateScanPolicyStore } from '../../../../../../../../../logic/store/pullstate/scanPolicy';
import quazarBaseUrl from '../../../../../../../../../utils/constants';

function PolicyInner({
  input, button, startScreen,
}) {
  const [data, setData] = useState({ number: '', date: '' });

  const Authorization = async () => {
    const { number, date } = data;
    const bDate = moment(date, 'DDMMYYYY').format('YYYY-MM-DD');
    try {
      const responce = await Axios.get(`/queue/patient/by-polis?polisNumber=${number}&birthDate=${bDate}`, { timeout: 15000, baseURL: quazarBaseUrl });
      const { data: axData } = responce;
      if (axData.success) {
        const { data: rData } = axData;
        if (rData) {
          const posts = await Axios.get(`/queue/patient/${rData.patient.id}/appointments`, { timeout: 15000, baseURL: quazarBaseUrl });
          if (posts.data.success) {
            const { data: postsData } = posts;
            if (postsData.success) {
              updateScanPolicyStore({ activeScreen: 'list', records: postsData.data });
            } else {
              updateScanPolicyStore({ activeScreen: 'error', errorText: '' });
            }
          }
        } else updateScanPolicyStore({ activeScreen: 'error', errorText: 'Записи на сегодня<br/> по данному полису отсутствуют' });
      } else {
        updateScanPolicyStore({ activeScreen: 'error', errorText: 'Мы сожалеем,<br/> данный полис не найден' });
      }
    } catch (e) {
      if (e.response.data.errorMessage.rus) {
        updateScanPolicyStore({ activeScreen: 'error', errorText: e.response.data.errorMessage.rus });
      } else {
        updateScanPolicyStore({ activeScreen: 'error', errorText: 'Мы сожалеем,<br/> сервер временно недоступен' });
      }
    }
  };

  const [sInput, setInput] = useState({ type: 'number', value: '' });

  const inputs = [
    {
      name: 'Номер полиса', param: 'number', type: 2, typeKey: 'number', defaultValue: '5058800842001540',
    },
    {
      name: 'Дата', param: 'date', type: 3, typeKey: 'number', defaultValue: '07011991',
    },
  ];

  const setValue = (value) => {
    setData((v) => ({ ...v, [sInput.param]: value }));
  };

  const onClickInput = (param, type) => {
    setInput({ param, type });
  };

  return (
    <PolicyContainer>
      <div className="policyBox">
        {inputs.map((el) => (
          <Input
            key={el.param}
            item={el}
            style={input}
            value={data[el.param]}
            id={el.param}
            type={el.type}
            clickInput={(v) => onClickInput(v, el.typeKey)}
          />
        ))}
        <Keyboard
          styleButton={button}
          login={50}
          id={sInput.type}
          width={button.width}
          setValue={setValue}
          value={data[sInput.param]}
          polisy
        />
      </div>
      <PolicyBB startScreen={startScreen} button={button} isInner Authorization={Authorization} />
    </PolicyContainer>
  );
}

export default PolicyInner;
